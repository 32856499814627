<!-- <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="8">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form cForm>
                <h1>Login</h1>
                <p class="text-medium-emphasis">Sign In to your account</p>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input autoComplete="username" cFormControl placeholder="Username" />
                </c-input-group>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input
                    autoComplete="current-password"
                    cFormControl
                    placeholder="Password"
                    type="password"
                  />
                </c-input-group>
                <c-row>
                  <c-col xs="6">
                    <button cButton class="px-4" color="primary">
                      Login
                    </button>
                  </c-col>
                  <c-col class="text-right" xs="6">
                    <button cButton class="px-0" color="link">
                      Forgot password?
                    </button>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
          <c-card [ngStyle]="{'width.%': 44}" class="text-white bg-primary py-5">
            <c-card-body class="text-center">
              <div>
                <h2>Sign up</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <button [active]="true" cButton class="mt-3" color="primary" routerLink="/register">
                  Register Now!
                </button>
              </div>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div> -->




<!-- LOGIN PAGE -->

<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col lg="7" md="9" xl="6">
        <c-card class="mx-4">
          <c-card-body class="p-4">
            <form [formGroup]="cForm"  (ngSubmit)="login()">
              <h1>LOGIN</h1>
              <c-input-group class="mb-3">
                <span cInputGroupText>
                  <svg cIcon name="cilUser"></svg>
                </span>
                <input autoComplete="login" cFormControl formControlName="username" placeholder="Username" />
              </c-input-group>
              <c-input-group class="mb-3">
                <span cInputGroupText>
                  <svg cIcon name="cilLockLocked"></svg>
                </span>
                <input autoComplete="new-password" cFormControl placeholder="Password" formControlName="password" type="password" />
              </c-input-group>
              <div class="d-grid">
                <button cButton type="submit" color="success">Login</button>
              </div>
            </form>
          </c-card-body>
        </c-card>
      </c-col>
    </c-row>

   <template *ngIf="text_error_message">
    <div>

      <c-alert color="secondary">
        A simple secondary alert with <a cAlertLink routerLink="./">an example link</a>.
        Give it a click if you like.
      </c-alert>

      <c-alert  color="warning" closeButton>
        {{text_error_message}}
      </c-alert >
      <c-alert
        color="danger"
        :show.sync="currentAlertCounter"
        closeButton
      >
        <!-- This alert will close after {{currentAlertCounter}} seconds. -->

    </c-alert >
    </div>
   </template>

  </c-container>
</div>

