<!--<c-header class="mb-4 d-print-none" position="sticky">-->
  <ng-container>
    <c-container [fluid]="true">
      <button
        toggle="visible"
        cHeaderToggler
        [cSidebarToggle]="sidebarId"
        class="ps-1"
      >
        <svg cIcon
          name="cilMenu"
          size="lg"
        ></svg>
      </button>
      <c-header-nav class="d-none d-lg-flex me-auto">
        <c-nav-item>
          <a cNavLink routerLink="/dashboard" routerLinkActive="active">
            Dashboard
          </a>
        </c-nav-item>
        <c-nav-item>
          <a cNavLink routerLink="/users" routerLinkActive="active">Utilisateurs</a>
        </c-nav-item>
        <c-nav-item>
          <a cNavLink routerLink="/settings" routerLinkActive="active">
            Réglages
          </a>
        </c-nav-item>
      </c-header-nav>
  
      <c-header-nav class="d-none d-lg-flex">
        <c-nav-item>
          <a routerLink="./" cNavLink>
            <svg cIcon name="cilBell" size="lg"></svg>
          </a>
        </c-nav-item>
        <c-nav-item >
          <a  cNavLink>
            <svg cIcon name="cilList" size="lg"></svg>
          </a>

        </c-nav-item>
        <c-nav-item>
          <a routerLink="./" cNavLink>
            <svg cIcon name="cilEnvelopeOpen" size="lg"></svg>
          </a>
        </c-nav-item>
      </c-header-nav>
      <c-header-nav class="ms-3">
        <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
      </c-header-nav>
  
    </c-container>
    <c-header-divider></c-header-divider>
    <c-container [fluid]="true">
      <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
    </c-container>
  </ng-container>
  <!--</c-header>-->
  
  <ng-template #userDropdown>
    <c-dropdown alignment="end" variant="nav-item">
      <button
        cButton
        color=""
        [caret]="false"
        cDropdownToggle
        class="py-0"
      >
        <c-avatar
          cTextColor="primary"
          shape="rounded-1"
          size="md"
          src="./assets/img/avatars/8.jpg"
          status="success"
        >
        </c-avatar>
      </button>
      <ul cDropdownMenu class="pt-0 pr-5 w-auto">
        <li>
          <h6 cDropdownHeader class="bg-light fw-semibold py-2">Compte</h6>
        </li>
        <li>
          <a routerLink="./" cDropdownItem>
            <svg cIcon class="me-2" name="cilBell"></svg>
            Updates
            <c-badge class="ms-2 float-end" color="info">
              42
            </c-badge>
          </a>
        </li>
        <li>
          <a routerLink="./" cDropdownItem>
            <svg cIcon class="me-2" name="cilEnvelopeOpen"></svg>
            Messages
            <c-badge class="ms-2 float-end" color="success">
              42
            </c-badge>
          </a>
        </li>
        <li>
          <a routerLink="./" cDropdownItem>
            <svg cIcon class="me-2" name="cilTask"></svg>
            Tâches
            <c-badge class="ms-2 float-end" color="danger">
              42
            </c-badge>
          </a>
        </li>
        <li>
          <a routerLink="./" cDropdownItem>
            <svg cIcon class="me-2" name='cilCommentSquare'></svg>
            Comments
            <c-badge class="ms-auto" color="warning">
              42
            </c-badge>
          </a>
        </li>
        <li>
          <h6 cDropdownHeader class="bg-light fw-semibold py-2">Réglages</h6>
        </li>
        <li></li>
        <li>
          <a routerLink="./" cDropdownItem>
            <svg cIcon class="me-2" name="cilUser"></svg>
            Profile
          </a>
        </li>
        <li class="nav-item dropdown" >
          <a data-toggle="dropdown"   cDropdownItem>
            <svg cIcon class="me-2" name="cilSettings"></svg>
            Réglages
          </a>


        </li>
        <li>
          <a routerLink="./" cDropdownItem>
            <svg cIcon class="me-2" name="cilCreditCard"></svg>
            Payements
            <c-badge class="ms-2 float-end" color="secondary">
              42
            </c-badge>
          </a>
        </li>
        <li>
          <a routerLink="./" cDropdownItem>
            <svg cIcon class="me-2" name="cilFile"></svg>
            Projects
            <c-badge class="ms-2 float-end" color="primary">
              42
            </c-badge>
          </a>
        </li>
        <li>
          <hr cDropdownDivider/>
        </li>
        <li>
          <a  cDropdownItem (click)="logout()">
            <svg cIcon class="me-2" name="cilLockLocked" ></svg>
            Se déconnecter
          </a>
        </li>
        <li></li>
      </ul>
    </c-dropdown>
  </ng-template>

  <!-- <ng-template>
    <form>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <input type="text"  class="form-control-plaintext" id="staticEmail" value="email@example.com">
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
        <div class="col-sm-10">
          <input type="password" class="form-control" id="inputPassword" placeholder="Password">
        </div>
      </div>
      <button type="submit" class="btn btn-primary mb-2">Confirm</button>
    </form>
  </ng-template> -->
  
  