<div class="fade-in">
    <c-card class="mb-4">
      <c-card-header>
        Theme colors
      </c-card-header>
      <c-card-body>
        <c-row>
            <h1>Bonjour</h1>
        </c-row>
      </c-card-body>
    </c-card>
  </div>
  