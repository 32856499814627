<!--<c-footer>-->
  <div>
    <a href="www.link.ecoboostergroup.com" target="_blank">Ecobooster Link</a>
    <span> &copy; 2022 all rights reserved</span>
  </div>
  <div class="ms-auto">
    Powered by Ecobooster
    <a href="#" target="_blank">
      <span> Farouk Transfert</span>
    </a>
  </div>
<!--</c-footer>-->
